<!-- <app-header></app-header> -->
<div class="heading_navbar laptop_view" style="position: fixed;  z-index: 1000;">
    <div style="width: 300px;display: inline-block;width: 30%;">
        <img class="page_logo" src="/assets/logo.jpg" alt="">
    </div>
    <span class="spacer"></span>
    <div style="display: inline-block;text-align: right;width: 70%;">
        <button class="menu_btn" (click)="scroll(ourmenu)">Menu</button>
        <button class="menu_btn" (click)="scroll(aboutus)">About Us</button>
        <button class="menu_btn" (click)="scroll(contactus)">Contact Us</button>
    </div>
</div>
<div class="heading_navbar mobile_view" style="position:fixed; z-index: 1000;">
    <div style="width: 300px;display: inline-block;width: 50%;">
        <img class="page_logo" src="/assets/logo.jpg" alt="">
    </div>
    <span class="spacer"></span>
    <div style="display: inline-block;text-align: right;width: 50%;">
        <!-- <button mat-button [matMenuTriggerFor]="items">Menu</button> -->
        <button mat-icon-button class="hamburger_icon" (click)="sidenav.open()">
            <mat-icon class="icon_display" >view_headline</mat-icon>
        </button>
        <!-- <mat-menu #items="matMenu">
            <button mat-menu-item>Menu</button>
            <button mat-menu-item>About Us</button>
            <button mat-menu-item>Contact Us</button>
        </mat-menu> -->
    </div>
</div>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav style="background-color: #FEFEFF; overflow-x: hidden;position: fixed; z-index: 1001;" #sidenav [opened]="screenWidth < 600" mode = "over" class="sidenav mat-elevation-z3" >
        <mat-nav-list>
            <mat-list-item (click)="[sidenav.close()]"></mat-list-item>
            <mat-list-item (click)="[sidenav.close()]"></mat-list-item>
            <mat-list-item (click)="[sidenav.close(),scroll(ourmenu)]">Menu</mat-list-item>
            <mat-list-item (click)="[sidenav.close(),scroll(aboutus)]">About Us</mat-list-item>
            <mat-list-item (click)="[sidenav.close(),scroll(contactus)]">Contact Us</mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="background-color: #FEFEFF;" [ngStyle]="{'display' : screenWidth < 600 ? 'none' : ''}">
        <div>
            <img style="margin-top: 84px;" src="/assets/smoky_landscape.png" class="images_section">
        </div>
        <div #ourmenu></div>
        <app-home></app-home>
        <div #aboutus></div>
        <app-about-us></app-about-us>
        <div #contactus></div>
        <app-contact-us></app-contact-us>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
<div [ngStyle]="{'display' : screenWidth < 600 ? '' : 'none'}">
    <div>
        <img style="margin-top: 84px;" src="/assets/smoky_landscape.png" class="images_section">
    </div>
    <div #ourmenu></div>
    <app-home></app-home>
    <div #aboutus></div>
    <app-about-us></app-about-us>
    <div #contactus></div>
    <app-contact-us></app-contact-us>
    <app-footer></app-footer>
</div>