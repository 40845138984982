import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule,} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog';
import {LayoutModule} from '@angular/cdk/layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatBadgeModule} from '@angular/material/badge';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

@NgModule({
  declarations: [],
  imports: [
    FormsModule,CommonModule, MatButtonModule, MatCheckboxModule,MatToolbarModule,MatSidenavModule,MatSortModule,
    MatIconModule,MatListModule,MatInputModule,MatCardModule,MatTabsModule,MatSnackBarModule,MatNativeDateModule,
    MatExpansionModule,LayoutModule,MatFormFieldModule,MatDatepickerModule,MatDialogModule,
    MatRadioModule,MatSelectModule,MatPaginatorModule,MatTableModule,MatStepperModule,MatAutocompleteModule,
    MatGridListModule,MatMenuModule,MatTooltipModule,MatProgressBarModule,MatBadgeModule,MatProgressSpinnerModule,
    //MatNativeDateModule
  ],
  exports:[
    // FlexLayoutModule
    FormsModule,MatButtonModule, MatCheckboxModule,MatToolbarModule,MatSidenavModule,MatSortModule,MatBadgeModule,
    MatIconModule,MatListModule,MatInputModule,MatCardModule,MatTabsModule,MatSnackBarModule,MatNativeDateModule,CdkTableModule,CdkTreeModule,
    MatExpansionModule,LayoutModule,MatFormFieldModule,MatDatepickerModule,MatDialogModule,MatProgressSpinnerModule,
    MatRadioModule,MatSelectModule,MatPaginatorModule,MatTableModule,MatStepperModule,MatAutocompleteModule,MatProgressBarModule,
    //MatNativeDateModule
    MatGridListModule,MatMenuModule,MatTooltipModule]
})
export class MaterialModule { }