<div>
    <div class="">
        <div class="navbar-nav">
            <span class="image">
                <img class="picture" src="/assets/logo.jpg"
                        alt="">
            </span>
            <span class="website_page_sidebutton">
                <div class="nav-item">
                    <button class="btn">Home</button>
                </div>
                <div class="nav-item">
                    <button class="btn">Menu</button>
                </div>
                <div class="nav-item">
                    <button class="btn">About</button>
                </div>
                <div class="nav-item">
                    <button class="btn">Contact</button>
                </div>
            </span>
        </div>

        <div class="FoodPage_one">
            <img src="/assets/smoky_landscape.png" class="image1">
        </div>
    </div>
</div>

