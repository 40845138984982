<div class="whole_page">
    <div class="container">
        <div class="heading">{{mainHeading}}</div>
    </div>
    <div class="section_text">
        <div class="details_wrap" *ngFor="let details of theDetails;">
            <div class="subHeading_text">{{details.subHeading}}</div>
            <div *ngIf="details.ImageSrc" class="image_tag"><img  [src]="details.ImageSrc" class="image"></div>
            <div class="lines_wrap" *ngFor="let lines of details.description;">
                <div class="list" *ngIf="lines.isList">
                    <div *ngIf="lines.isOL">
                        <ol>
                            <li *ngFor="let values of lines.list">{{values.value}}</li>
                        </ol>
                    </div>
                    <div *ngIf="!lines.isOL">
                        <ul>
                            <li *ngFor="let values of lines.list">{{values.value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="description_text">{{lines.value}}</div>
                
            </div>
        </div>
    </div>
</div>