<div class="contact" id="contact">
    <div class="heading">
      Contact <span>us</span>
    </div>
    <div class="row" style="margin: 0px;padding: 0px;">
      <div class="col-md-5 text_section">
        <h3>Let's Connect!</h3>
        <p>ROKSAN LEY KITCHENS (SMOKY BAY) Η Νο. 3-6-195/A, Ground Floor, Himayath Nagar, Hyderabad, Telangana-500029</p>
        <i class="fa-solid fa-phone"><span>+91 81436 94777</span></i><br><br>
        <i class="fa-solid fa-location-dot"><span>Hyderabad, Telangana, India</span></i>
        <!-- <i class="fa-solid fa-envelope"><span>info@mail.com</span></i><br><br> -->
      </div>
      <div class="col-md-7 form_section">
        <form action="#">
          <div class="mb-3 mt-3">
            <input type="text" class="form-control" id="name" placeholder="Enter Name" required>
          </div>
          <div class="mb-3 mt-3">
            <input type="email" class="form-control" id="email" placeholder="Enter Email" required>
          </div>
          <div class="mb-3 mt-3">
            <input type="number" class="form-control" id="number" placeholder="Enter Number" required>
          </div>
           <textarea  class="form-control" id="comment" rows="5" name="text" placeholder="Enter Address" required></textarea>
           <button type="submit" class="order-btn">Order Now</button>
        </form>
      </div>
    </div>
</div>