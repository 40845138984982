<div class="list_of_menu_items">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3" style="margin: 15px 0px;" *ngFor="let data of MenuDetails.theData">
            <mat-card class="custom_mat_card">
                <img [src]="data.imagesrc" class="custom_image">
                <div class="row Iteminfo" style="margin: 0px;padding: 10px;">
                    <div class="col-8" style="padding: 0px 5px 0px 0px;margin: 0px;">
                        <div class="side_heading">{{data.side_heading}}</div>
                    </div>
                    <div class="col-4 item_price" style="padding: 0px;margin: 0px;">
                        <div class="side_heading" style="text-align: right;padding-right: 5px;">{{data.price}}</div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>