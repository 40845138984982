import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
const routes:Routes=[ 
  {
    path:'',redirectTo: 'home', pathMatch: 'full'
  },
  { 
    path : 'home',component:HomeComponent
  },
  {
    path:'aboutus',component:AboutUsComponent
  },
  {
    path:'contactus',component:ContactUsComponent
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
