import { Component,Input,OnInit } from '@angular/core';
export class MenuData{
  heading_text: string;
  theData:theData[]
}
export class theData{
  imagesrc: string;
  side_heading: string;
  price: any;
}
@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.css']
})
export class MenuItemsComponent implements OnInit {
  heading_text;
  theData;
  @Input() MenuDetails: MenuData;
  constructor() { 
    window.resizeTo = () =>{
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }
  }
  screenWidth;
  screenHeight;
  ngOnInit(): void {
  }

}
