import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'smokybay';
  scroll(el) {
    el.scrollIntoView({ behavior: "smooth" });
  }
  constructor() {
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }
   }
   screenWidth;
   screenHeight;

}