<div class="row" style="margin: 0px;padding: 0px;">
    <div class="section_header">Our Menu</div>
    <div class="smoky_bay_menu">
        <div class="menu_items">
            <button class="items" (click)="MenuItemClick('Burger')">Burger</button>
            <button class="items" (click)="MenuItemClick('Sandwich')">Sandwich</button>
            <button class="items" (click)="MenuItemClick('Milkshake')">Milkshake</button>
            <button class="items" (click)="MenuItemClick('Momos')">Momos</button>
            <button class="items" (click)="MenuItemClick('Shawarma')">Shawarma</button>
            <button class="items" (click)="MenuItemClick('Coffee & Beverage')">Coffee & Beverage</button>
            <button class="items" (click)="MenuItemClick('Waffle')">Waffle</button>
            <button class="items" (click)="MenuItemClick('Ice Cream')">Ice Cream</button>
            <button class="items" (click)="MenuItemClick('Pasta')">Pasta</button>
            <button class="items" (click)="MenuItemClick('Munchies')">Munchies</button>
        </div>
    </div>
</div>

<app-menu-items [MenuDetails]="MenuData"></app-menu-items>