import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  mainHeading= "About Us";
  constructor() { }

  theDetails= [
    {
      subHeading: "",
      description: [
        {
          value: "Welcome to Smoky Bay! Located in the heart of Himayat Nagar, Hyderabad, we are your destination for delicious delights and delightful experiences.At Smoky Bay, we pride ourselves on offering a diverse menu that caters to all tastes. From our signature Charcoal Shawarma, available with Rumali or Khuboos, to our mouthwatering Nachos, Sandwiches, Thickshakes, and Milkshakes, there's something for everyone to enjoy.What sets us apart is our commitment to quality. We go above and beyond to source the freshest ingredients, ensuring that each dish is bursting with flavor and made with care. Every bite at Smoky Bay is a testament to our dedication to providing you with the best dining experience possible. "
        }
      ]

    },
    {
      subHeading: "",
      description: [
        {
          value: "But it's not just about the food here; it's about the atmosphere too. Our cafe boasts a warm and inviting ambiance, perfect for catching up with friends or simply unwinding after a long day. Our friendly staff are always on hand to make you feel welcome and ensure that your visit is nothing short of exceptional.          "
        }
      ]
    },
    {
      subHeading: "",
      description: [
        {
          // list: [
          //   {
          //     value: "Supply-Friendly Platform: We prioritize fairness and ownership, creating a platform that's friendly to both our drivers and users.",
          //   },
          //   {
          //     value: "Inclusive Community: We believe in building a diverse and interconnected community, where everyone's journey matters.",
          //   },
          //   {
          //     value: "aInnovation in Mobility: From cutting-edge technology to unique benefits, we're reshaping the mobility landscape.",
          //   }
          // ],
          // isOL: false,
          // isList: true,
          value: "At Smoky Bay, we believe in creating memories with every meal. Whether you're stopping by for a quick bite or lingering over a leisurely lunch, we're here to make sure that your experience is unforgettable.So why not join us today? Come and discover the flavors of Smoky Bay for yourself. We can't wait to welcome you!"
        }
      ]
    }

  ]

  ngOnInit(): void {
  }

}
