import { Component, OnInit } from '@angular/core';
import { MenuData } from 'src/app/helpers/menu-items/menu-items.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }
  WAFFLES: MenuData = {
    heading_text: "WAFFLES",
    theData: [
      {
        imagesrc: "/assets/ChocolateChipWaffle.png",
        side_heading: "Chocolate Chip Waffle",
        price: "₹​ 100",
      },
      {
        imagesrc: "/assets/RedVelvetWaffle.png",
        side_heading: "Red Velvet Waffle",
        price: "₹​ 110",
      },
      {
        imagesrc: "/assets/HoneyButterWaffle.png",
        side_heading: "Honey Butter Waffle",
        price: "₹​ 100",
      },
      {
        imagesrc: "/assets/VanillaIce-creamWaffle.png",
        side_heading: "Vanilla Ice-cream Waffle",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/ChocolateIce-creamWaffle.png",
        side_heading: "Chocolate Ice-cream Waffle",
        price: "₹​ 120",
      }
    ]
  };
  MUNCHIES: MenuData = {
    heading_text: "MUNCHIES",
    theData: [
      {
        imagesrc: "/assets/FrenchFries.png",
        side_heading: "French Fries",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/PlainCheeseFries.png",
        side_heading: "Plain Cheese Fries ",
        price: "₹​ 90",
      },
      {
        imagesrc: "/assets/PeriPeriFries.png",
        side_heading: "Peri Peri Fries",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/PeriPeriCheeseFries.png",
        side_heading: " Peri Peri Cheese Fries",
        price: "₹​ 90",
      },
      {
        imagesrc: "/assets/Nachos.png",
        side_heading: "Nachos",
        price: "₹​ 90",
      }, {
        imagesrc: "/assets/UnlimitedNachos.png",
        side_heading: "Unlimited Nachos ",
        price: "₹​ 110",
      },
      {
        imagesrc: "/assets/CheeseBombJalapenoPoppers.png",
        side_heading: " Cheese Bomb Jalapeno Poppers",
        price: "₹​ 130",
      },
      {
        imagesrc: "/assets/FalafelBites.png",
        side_heading: "  Falafel Bites ",
        price: "₹​ 130",
      },
      {
        imagesrc: "/assets/ChickenPopcorns.png",
        side_heading: "Chicken Popcorns",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/GoldenChickenNuggets.png",
        side_heading: " Golden Chicken Nuggets",
        price: "₹​ 120",
      },
    ]
  }
  MOMOS: MenuData = {
    heading_text: "MOMOS",
    theData: [
      {
        imagesrc: "/assets/VegMomoSteamedFried.png",
        side_heading: " Veg Momo Steamed / Fried ",
        price: "₹​ 70/80",
      },
      {
        imagesrc: "/assets/PaneerMomoSteamedFried.png",
        side_heading: " Paneer Momo Steamed / Fried ",
        price: "₹​ 90/100",
      },
      {
        imagesrc: "/assets/ChickenMomoSteamedFried.png",
        side_heading: " Chicken Momo Steamed / Fried ",
        price: "₹​ 100/110",
      }
    ]
  }
  SANDWICHES: MenuData = {
    heading_text: "SANDWICHES ",
    theData: [
      {
        imagesrc: "/assets/BombaySandwich.png",
        side_heading: " Bombay Sandwich  ",
        price: "₹​ 110",
      },
      {
        imagesrc: "/assets/MushroomCheeseSandwich.png",
        side_heading: " Mushroom Cheese Sandwich ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/Corn&CheeseSandwich.png",
        side_heading: " Corn & Cheese Sandwich  ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/PaneerSandwich.png",
        side_heading: "Paneer Sandwich ",
        price: "₹​ 130",
      },
      {
        imagesrc: "/assets/ChickenSandwich.png",
        side_heading: " Chicken Sandwich  ",
        price: "₹​ 140",
      },
      {
        imagesrc: "/assets/ChickenCheeseSandwich.png",
        side_heading: "Chicken Cheese Sandwich  ",
        price: "₹​ 150",
      },
      {
        imagesrc: "/assets/ChickenClubSandwich.png",
        side_heading: "Chicken Club Sandwich  ",
        price: "₹​ 140",
      },
      {
        imagesrc: "/assets/NutellaSandwich.png",
        side_heading: " Nutella Sandwich ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/NutellaCheeseSandwich.png",
        side_heading: " Nutella Cheese Sandwich  ",
        price: "₹​ 130",
      },
    ]
  }
  PASTA: MenuData = {
    heading_text: "PASTA",
    theData: [
      {
        imagesrc: "/assets/Arabitta(VegChicken).png",
        side_heading: "Arabitta ( Veg / Chicken ) ",
        price: "₹​ 150  170",
      },
      {
        imagesrc: "/assets/Alfredo(VegChicken).png",
        side_heading: " Alfredo ( Veg / Chicken )",
        price: "₹​ 150  170",
      },
      {
        imagesrc: "/assets/MacAndCheese(VegChicken) .png",
        side_heading: "Mac and Cheese ( Veg/ Chicken ) ",
        price: "₹​ 150  170",
      },
    ]
  }
  BURGERS: MenuData = {
    heading_text: "BURGERS",
    theData: [
      {
        imagesrc: "/assets/VeggieDelightBurger.png",
        side_heading: "Veggie Delight Burger",
        price: "₹​ 90",
      },
      {
        imagesrc: "/assets/CrispyPaneerBurger.png",
        side_heading: "Crispy Paneer Burger",
        price: "₹​ 100",
      },
      {
        imagesrc: "/assets/CheeseVegetableBurger.png",
        side_heading: "Cheese Vegetable Burger ",
        price: "₹​ 90",
      },
      {
        imagesrc: "/assets/VegDoubleCheeseBurger.png",
        side_heading: "Veg Double Cheese Burger ",
        price: "₹​ 110",
      },
      {
        imagesrc: "/assets/ChickenBurger.png",
        side_heading: "Chicken Burger ",
        price: "₹​ 130",
      },
      {
        imagesrc: "/assets/ChickenCheeseBurger.png",
        side_heading: "Chicken Cheese Burger ",
        price: "₹​ 140",
      },
    ]
  }
  SHAWARMA: MenuData = {
    heading_text: "SHAWARMA",
    theData: [
      {
        imagesrc: "/assets/ChickenShawarmaWithSalad.png",
        side_heading: " Chicken Shawarma with Salad",
        price: "₹​ 100",
      },
      {
        imagesrc: "/assets/SplChickenShawarmaWithoutSalad.png",
        side_heading: " Spl Chicken Shawarma without Salad ",
        price: "₹​ 140",
      },
      {
        imagesrc: "/assets/SpicyChickenShawarma.png",
        side_heading: " Spicy Chicken Shawarma ",
        price: "₹​ 110",
      },
    ]
  }
  BEVERAGES: MenuData = {
    heading_text: "COFFEE & BEVERAGES",
    theData: [
      {
        imagesrc: "/assets/LemonBasilSoda.png",
        side_heading: "  Lemon Basil Soda ",
        price: "₹​ 90",
      },
      {
        imagesrc: "/assets/VirginMojito.png",
        side_heading: " Virgin Mojito  ",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/BlueLagoon.png",
        side_heading: " Blue Lagoon ",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/WatermelonCrusher.png",
        side_heading: " Watermelon Crusher",
        price: "₹​ 90",
      },
      {
        imagesrc: "/assets/HotChocolate.png",
        side_heading: "   Hot Chocolate  ",
        price: "₹​ 100",
      },
      {
        imagesrc: "/assets/Coffee.png",
        side_heading: "  Coffee",
        price: "₹​ 40",
      },
      {
        imagesrc: "/assets/ColdCoffee.png",
        side_heading: " Cold Coffee ",
        price: "₹​ 120",
      },
    ]
  }
  MILKSHAKES: MenuData = {
    heading_text: "MILKSHAKES ",
    theData: [
      {
        imagesrc: "/assets/VanillaMilkshake.png",
        side_heading: "  Vanilla Milkshake  ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/StrawberryMilkshake.png",
        side_heading: "  Strawberry Milkshake   ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/BananaMilkshake.png",
        side_heading: " Banana Milkshake ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/NutellaShake.png",
        side_heading: " Nutella Shake  ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/OreoShake.png",
        side_heading: "  Oreo Shake  ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/ChocolateShake.png",
        side_heading: " Chocolate Shake   ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/MangoShake.png",
        side_heading: " Mango Shake ( Seasonal ) ",
        price: "₹​ 130",
      },
    ]
  }
  SUNDAE: MenuData = {
    heading_text: "ICE CREAM & SUNDAE  ",
    theData: [
      {
        imagesrc: "/assets/VanillaIce-cream.png",
        side_heading: " Vanilla Ice-cream  ",
        price: "₹​ 70",
      },
      {
        imagesrc: "/assets/ButterScotchIce-cream.png",
        side_heading: "  Butter Scotch Ice-cream   ",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/ChocolateIce-cream.png",
        side_heading: " Chocolate Ice-cream  ",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/StrawberryIce-cream.png",
        side_heading: " Strawberry Ice-cream   ",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/MangoIce-cream.png",
        side_heading: "Mango Ice-cream   ",
        price: "₹​ 80",
      },
      {
        imagesrc: "/assets/HotFudgeSundae.png",
        side_heading: " Hot Fudge Sundae   ",
        price: "₹​ 100",
      },
      {
        imagesrc: "/assets/StrawberrySundae.png",
        side_heading: "Strawberry Sundae  ",
        price: "₹​ 100",
      },
      {
        imagesrc: "/assets/CaramelDelight.png",
        side_heading: " Caramel Delight  ",
        price: "₹​ 120",
      },
      {
        imagesrc: "/assets/OreoBlast.png",
        side_heading: " Oreo Blast   ",
        price: "₹​ 110",
      },
      {
        imagesrc: "/assets/Choco-NuttyDream.png",
        side_heading: " Choco - Nutty Dream   ",
        price: "₹​ 130",
      },
      {
        imagesrc: "/assets/DeathByChocolate.png",
        side_heading: " Death By Chocolate  ",
        price: "₹​ 110",
      },
    ]
  }

  ngOnInit(): void {
    this.MenuData = this.BURGERS
  }
  MenuData
  MenuItemClick(FoodCategory: string) {
    switch (FoodCategory) {
      case 'Burger':
        this.MenuData = this.BURGERS;
        break;
      case 'Sandwich':
        this.MenuData = this.SANDWICHES;
        break;
      case 'Milkshake':
        this.MenuData = this.MILKSHAKES;
        break;
      case 'Momos':
        this.MenuData = this.MOMOS;
        break;
      case 'Shawarma':
        this.MenuData = this.SHAWARMA;
        break;
      case 'Coffee & Beverage':
        this.MenuData = this.BEVERAGES;
        break;
      case 'Waffle':
        this.MenuData = this.WAFFLES;
        break;
      case 'Ice Cream':
        this.MenuData = this.SUNDAE;
        break;
        case 'Pasta':
        this.MenuData = this.PASTA;
        break;
        case 'Munchies':
        this.MenuData = this.MUNCHIES;
        break;
      default:
        this.MenuData = this.BURGERS;
        break;
    }
  }
}